import React, { useEffect, useState } from 'react';
import { Form, FormControl, Button, FormSelect, FormCheck } from 'react-bootstrap';
import { BasicFetch } from '../../../utils/BasicFetch';
import { VenuePicker } from '../../../utilityComponents/VenuePicker';
import { blankEvent, Event } from '../../../types/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_NUMBER_OF_GAMES = 20;

const dateToEastern = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

const dateToInput = (date: Date) => {
  return date.toISOString().split('T')[0];
}
const timeToInput = (date: Date) => {
  // convert to local time
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  // return time without milliseconds
  return date.toISOString().split('T')[1].split('.')[0];
}


export const CreateEvent = ({ backToMenu, onCreate, editingEvent }: { backToMenu?: () => void, onCreate?: () => void, editingEvent: Event | null }) => {
  if (editingEvent && (typeof editingEvent.eventDate.start === 'string' || typeof editingEvent.eventDate.end === 'string')) {
    if (typeof editingEvent.eventDate.start === 'string') {
      editingEvent.eventDate.start = new Date(editingEvent.eventDate.start);

    }
    if (typeof editingEvent.eventDate.end === 'string') {
      editingEvent.eventDate.end = new Date(editingEvent.eventDate.end);
    }
  }
  const [currentEvent, setCurrentEvent] = useState<Event>(editingEvent || blankEvent);
  const [numberOfGames, setNumberOfGames] = useState(DEFAULT_NUMBER_OF_GAMES);
  const [multipleDays, setMultipleDays] = useState(false);


  // this is the hours and minutes of the start and end time
  const [currentStartTime, setCurrentStartTime] = useState(timeToInput(new Date(currentEvent.eventDate.start) || new Date()));
  const [currentEndTime, setCurrentEndTime] = useState(timeToInput(new Date(currentEvent.eventDate.end) || new Date()));

  const createEvent = () => {
    if (!currentEvent.title) {
      (window as any).showAlertMessage("Title is required.");
      return;
    }
    if (!currentEvent.description) {
      (window as any).showAlertMessage("Description is required.");
      return;
    }
    if (!currentEvent.venue.id) {
      (window as any).showAlertMessage("Venue is required.");
      return;
    }
    if (!currentEvent.eventDate.start) {
      (window as any).showAlertMessage("Start Date is required.");
      return;
    }

    if (!currentEvent.eventDate.end) {
      (window as any).showAlertMessage("End Date is required.");
      return;
    }
    if (!currentEvent.eventDate.start) {
      (window as any).showAlertMessage("Start Time is required.");
      return;
    }
    if (!currentEvent.eventDate.end) {
      (window as any).showAlertMessage("End Time is required.");
      return;
    }
    if (currentEvent.eventDate.start > currentEvent.eventDate.end) {
      (window as any).showAlertMessage("Start Date/Time cannot be after End Date/Time.");
      return;
    }
    const newEvent = { ...currentEvent };
    // set the date to eastern time
    // add 1 day to each date to offset the time difference
    currentEvent.eventDate.start = new Date(currentEvent.eventDate.start.getTime() + 86400000);
    currentEvent.eventDate.end = new Date(currentEvent.eventDate.end.getTime() + 86400000);

    // add the time to the date
    newEvent.eventDate.start.setHours(parseInt(currentStartTime.split(':')[0]));
    newEvent.eventDate.start.setMinutes(parseInt(currentStartTime.split(':')[1]));
    newEvent.eventDate.end.setHours(parseInt(currentEndTime.split(':')[0]));
    newEvent.eventDate.end.setMinutes(parseInt(currentEndTime.split(':')[1]));

    // set the date to eastern time
    newEvent.eventDate.start = dateToEastern(newEvent.eventDate.start);
    newEvent.eventDate.end = dateToEastern(newEvent.eventDate.end);

    BasicFetch({
      url: editingEvent?.id ? `events/${currentEvent.id}/edit` : 'events/create',
      method: 'POST',
      data: {
        ...newEvent,

        numberOfGames
      },
      onSuccess: () => {
        onCreate?.();
      },
      onFail: () => {
        (window as any).showAlertMessage("Failed to create Event. Please try again later.");
      }
    })
  }
  const setDate = (date: Date) => {
    // if the new date is after the end date, set the end date to the new date
    // set the date to eastern time

    if (date > currentEvent.eventDate.end) {
      setCurrentEvent({
        ...currentEvent,
        eventDate: {
          ...currentEvent.eventDate,
          start: date,
          end: date
        }
      })
    } else {
      setCurrentEvent({
        ...currentEvent,
        eventDate: {
          ...currentEvent.eventDate,
          start: date,
          end: !multipleDays ? date : currentEvent.eventDate.end
        }
      })
    }


  }
  const setEndDate = (date: Date) => {
    // set the date to eastern time
    setCurrentEvent({
      ...currentEvent,
      eventDate: {
        ...currentEvent.eventDate,
        end: date
      }
    })
  }
  const setStartTime = (time: string) => {
    // remove milliseconds
    setCurrentStartTime(time.split('.')[0]);
  }
  const setEndTime = (time: string) => {
    // remove milliseconds
    setCurrentEndTime(time.split('.')[0]);
  }

  return <>
    {backToMenu && <div className='d-flex flex-row'>
      <Button variant='outline-secondary' onClick={backToMenu}>
        <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
        Back to Menu
      </Button>
    </div>}
    <Form>
      <div className='mb-3'>
        <label>Title</label>
        <FormControl value={currentEvent.title} onChange={(e) => setCurrentEvent({ ...currentEvent, title: e.target.value })} />
      </div>
      <div className='mb-3'>
        <label>Description</label>
        <FormControl as="textarea" rows={3} value={currentEvent.description} onChange={(e) => setCurrentEvent({ ...currentEvent, description: e.target.value })} />
      </div>
      <div className='mb-3'>
        <label>Venue</label>
        <VenuePicker
          presetVenueId={currentEvent.venue.id}
          onPick={(venueId) => setCurrentEvent({ ...currentEvent, venue: { ...currentEvent.venue, id: venueId || -1 } })}
        />
      </div>

      <div className='mb-3'>
        <label>Start Date</label>
        <FormControl type="date" value={
          dateToInput(currentEvent.eventDate.start)
        } onChange={(e) => setDate(new Date(e.target.value))} />
      </div>

      <div className='mb-3 d-flex flex-row'>
        <FormCheck type="checkbox" checked={multipleDays} onChange={() => setMultipleDays(!multipleDays)} />
        <label className='ms-2'>Multiple Day Event</label>
      </div>

      {multipleDays && <div className='mb-3'>
        <label>End Date</label>
        <FormControl type="date" value={
          dateToInput(currentEvent.eventDate.end)
        } onChange={(e) => setEndDate(new Date(e.target.value))} />
      </div>}


      <div className='mb-3'>
        <label>Start Time</label>
        <FormControl type="time" value={currentStartTime} onChange={(e) => setStartTime(e.target.value)} />
      </div>

      <div className='mb-3'>
        <label>End Time</label>
        <FormControl type="time" min={
          currentStartTime
        } value={currentEndTime} onChange={(e) => setEndTime(e.target.value)} />
      </div>


      {!currentEvent?.id && <div className='mb-3'>
        <label>Number of Games</label>
        <FormControl type="number" value={numberOfGames} onChange={(e) => setNumberOfGames(parseInt(e.target?.value))} />
      </div>}
      <div className='mb-3 d-flex flex-row justify-content-center'>
        <Button onClick={createEvent}>
          {editingEvent?.id ? 'Edit' : 'Create'} Event
        </Button>
      </div>
    </Form>
  </>
} 