import React, { useEffect } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import './css/animate.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './scss/custom.scss';
import './App.css';
import { Menu } from './screens/Menu';
import { LoginContainer } from './screens/Login';
import { CreateEvent } from './screens/admin/events/CreateEvent';
import { EventList } from './screens/admin/events/EventList';
import { CreateVenue } from './screens/admin/CreateVenue';
import { CreateOrganization } from './screens/admin/CreateOrganization';
import { User } from './types/User';
import { BasicFetch } from './utils/BasicFetch';
import { PlayGame } from './screens/play/PlayGame';
import { EventCalendar } from './screens/EventCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { CreateGameMode } from './screens/admin/CreateGameMode';
import { HelpModal } from './utilityComponents/HelpModal';
import { EventPlayersList } from './screens/admin/players/EventPlayersList';
import { EditEventGames } from './screens/admin/events/EditEventGames';

type ScreenObject = {
  visible: boolean
}

export type Screens = {
  menu: ScreenObject,
  login: ScreenObject,
  createEvent: ScreenObject,
  eventList: ScreenObject,
  createVenue: ScreenObject,
  editOrganization: ScreenObject,
  playGame: ScreenObject,
  eventCalendar: ScreenObject,
  createPatterns: ScreenObject,
  viewPlayers: ScreenObject,
  viewGames: ScreenObject
};

const SCREEN_VISIBILITY: Screens = {
  menu: {
    visible: false,
  },
  login: {
    visible: false
  },
  createEvent: {
    visible: false
  },
  eventList: {
    visible: false
  },
  createVenue: {
    visible: false
  },
  editOrganization: {
    visible: false
  },
  playGame: {
    visible: false
  },
  eventCalendar: {
    visible: false
  },
  createPatterns: {
    visible: false
  },
  viewPlayers: {
    visible: false
  },
  viewGames: {
    visible: false
  }
};

const clearGameSearch = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('playingGame');
  url.searchParams.delete('eventId');
  window.history.pushState({}, '', url.toString());
}

const logout = () => {
  BasicFetch({
    url: 'logout',
    method: 'POST',
    onSuccess: () => {
      localStorage.setItem("cookie", '')
      window.location.reload();
    },
    onFail: () => {
      (window as any).showAlertMessage("Failed to logout. Please try again later.")
    }
  })
}

function App() {

  const url = new URL(window.location.href);
  const play = url.searchParams.get('playingGame');
  const eventId = url.searchParams.get('eventId');

  let initialScreen = { ...SCREEN_VISIBILITY, login: { visible: true } };
  if (play) {
    initialScreen = { ...SCREEN_VISIBILITY, playGame: { visible: true } }
  } else {
    url.searchParams.delete('playingGame');
    url.searchParams.delete('eventId');
  }

  const [screen, setScreen] = React.useState<Screens>(initialScreen);
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const [currentEventId, setCurrentEventId] = React.useState<number | null>(eventId ? parseInt(eventId) : null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [fadeAlert, setFadeAlert] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState('danger');

  const fetchUser = () => {
    BasicFetch({
      url: 'user/get_user',
      method: 'GET',
      onSuccess: (data) => {
        console.log(data);
        setCurrentUser(data.user);
        if (screen.login.visible) {
          setScreen({ ...SCREEN_VISIBILITY, menu: { visible: true } })
        }
      },
      onFail: () => {
        console.log("Failed to get user. Please try again later.")
      }
    })
  }

  useEffect(() => {
    fetchUser();
  }, [])

  const showAlertMessage = (alertMessage: string, alertType?: string) => {
    setAlertMessage(alertMessage);
    setAlertType(alertType || 'danger');
    setShowAlert(true);
    setFadeAlert(false);
    setTimeout(() => {
      setFadeAlert(true);
    }, 2500);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000)
  }

  const setScreenVisibility = (screen: string, editingEventId: number | null = null) => {
    const newScreen = { ...SCREEN_VISIBILITY, [screen]: { visible: true } };
    setCurrentEventId(editingEventId);
    setScreen(newScreen);
  }


  (window as any).showAlertMessage = showAlertMessage;

  const canCreateEvent = currentUser?.admin || (currentUser?.organization && currentUser?.organization?.permissions.createEvents);
  const canEditOrganization = currentUser?.admin || (currentUser?.organization && currentUser?.organization?.permissions.editOrganization);


  return (
    <div className="App px-2">
      <HelpModal show={showHelp} handleClose={() => setShowHelp(false)} />
      <Offcanvas
        show={showSettings}
        onHide={() => setShowSettings(false)}
        placement='start'
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='d-flex flex-column h-100'>
            {canEditOrganization && <Button variant='primary' onClick={() => {
              setShowSettings(false);
              setScreen({ ...SCREEN_VISIBILITY, editOrganization: { visible: true } })
            }} className='mb-3'>Edit Organization</Button>}
            {canCreateEvent && <Button
              variant='primary'
              className='mb-3'
              onClick={() => {
                setShowSettings(false);
                setScreen({ ...SCREEN_VISIBILITY, createVenue: { visible: true } })
              }}
            >
              Create Venue
            </Button>}
            {
              (currentUser?.admin || currentUser?.organization?.permissions.callNumbers) && <Button
                className='mb-3'
                onClick={() => {
                  setShowSettings(false);
                  setScreen({ ...SCREEN_VISIBILITY, createPatterns: { visible: true } })
                }}
              >
                Create Game Patterns
              </Button>
            }
            <div className='mt-auto d-flex flex-column'>
              <Button className='mb-3 text-light d-none' variant='secondary' onClick={() => setShowHelp(true)}>
                Help
              </Button>
              <Button onClick={logout}>
                Logout
              </Button>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>

      <main>
        <div id='app-container' className='container bg-light py-3 mt-3 rounded'>
          {screen.menu.visible && <Menu
            setScreen={setScreenVisibility}
            currentUser={currentUser}
          />}
          {screen.login.visible && <LoginContainer setScreen={(screen) => {
            if (screen === 'menu') {
              fetchUser();
            }
            setScreenVisibility(screen);
          }} />}
          {screen.createEvent.visible && <CreateEvent backToMenu={() => setScreenVisibility('menu')} editingEvent={null} />}
          {screen.eventList.visible && <EventList backToMenu={() => setScreenVisibility('menu')} />}
          {screen.createVenue.visible && <CreateVenue backToMenu={() =>
            setScreenVisibility('menu')
          } />}
          {screen.editOrganization.visible && currentUser && <CreateOrganization user={currentUser} backToMenu={() =>
            setScreenVisibility('menu')
          } />}
          {screen.eventCalendar.visible && <EventCalendar backToMenu={() =>
            setScreenVisibility('menu')
          } />}
          {screen.playGame.visible && currentEventId && <PlayGame
            eventId={currentEventId}
            backToMenu={() => {
              clearGameSearch();
              setScreenVisibility('menu')
            }}
          />}
          {screen.createPatterns.visible && <CreateGameMode backToMenu={() =>
            setScreenVisibility('menu')
          } />}
          {screen.viewPlayers.visible && <EventPlayersList
            eventId={currentEventId}
            backToMenu={() => {
              setScreenVisibility('menu')
            }}
          />}
          {screen.viewGames.visible && <EditEventGames
            eventId={currentEventId}
            goBack={() => {
              setScreenVisibility('menu')
            }}
          />}
        </div>

      </main>
      {showAlert && <div id='alert-container'><div id="alert" className={`${fadeAlert ? 'fadeOut' : 'fadeIn'} bg-${alertType}-subtle animated border border-${alertType}`}>{alertMessage}</div></div>}

      {currentUser && <div id='tool-bar' className='d-flex bg-secondary-subtle'>
        <div className='container d-flex'>
          <div className='align-self-end d-flex ms-auto'>
            <h5 className='me-3'>{currentUser?.first_name}</h5>
            <FontAwesomeIcon onClick={() => setShowSettings(!showSettings)} className='my-auto' size='xl' icon={faCog} />
          </div>
        </div>
      </div>}
    </div>
  );
}

export default App;
