import React, { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, Tabs, Tab, Modal } from "react-bootstrap";

import { CalledNumberBoard } from "../../utilityComponents/CalledNumberBoard";
import { BasicFetch } from "../../utils/BasicFetch";
import { Board } from "../../types/Board";
import { GamePicker } from "../../utilityComponents/GamePicker";
import { Game } from "../../types/Game";
import { Pattern } from "../../types/Pattern";
import { PatternDisplay } from "../../utilityComponents/PatternDisplay";
const LETTERS = ['B', 'I', 'N', 'G', 'O'];

const WinnerModal = ({
  boards,
  closeModal,
  show,
}: {
  boards: Board[],
  closeModal: () => void,
  show: boolean,
}) => {



  return <Modal show={show} onHide={closeModal} className="animated flash">
    <Modal.Header closeButton>
      <Modal.Title>Bingo!</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      {boards.map(board => (
        <div className='mx-auto d-flex flex-column align-items-center'>
          <div className='mt-3'>
            <BoardDisplay board={board} />
          </div>
        </div>
      ))}

    </Modal.Body>
  </Modal>
}

const Number = ({ number, called, winning, boardId }: { number: number, called: boolean, winning?: boolean, boardId?: number }) => {

  return (
    <div className='d-flex gap-1 justify-content-center align-items-center my-auto' style={{ width: '30px', height: '30px', border: '1px solid black' }}>
      <div className={`${called ? 'bg-primary round d-flex text-light' : 'd-flex'} ${winning ? 'bg-success' : ''}`}
        style={{ width: '30px', height: '30px' }}
      >
        {!boardId && <span className="m-auto">{number === 0 ? '' : number}</span>}
        {boardId && <small className="mt-auto"><small >{boardId}</small></small>}
      </div>
    </div>
  )
}


export const BoardDisplay = ({
  board
}: {
  board: Board;
}) => {
  const numbers = board.numbers

  return (
    <div>
      <div className="d-flex flex-row mb-1 gap-1">
        {LETTERS.map((letter, i) => {
          return (
            <div key={i}>
              <div className='d-flex justify-content-center align-items-center bg-dark text-light' style={{ width: '30px', height: '30px', border: '1px solid black' }}>
                {letter}
              </div>
            </div>
          )
        }
        )}
      </div>
      {numbers.map((row, i) => {
        return (
          <div key={i} className='d-flex flex-row mx-auto gap-1 mb-1'>
            {row.map((number, j) => {
              return (
                <Number
                  key={j}
                  number={number.number}
                  called={number.called}
                  boardId={j === 2 && i === 2 ? board.id : undefined}
                />
              )
            }
            )}
          </div>
        )
      }
      )}
    </div>
  )
}

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>(() => { });

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current(), delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


const getBoards = (gameId: number | null, setBoards: (boards: Board[]) => void, setCalledNumbers: (calledNumbers: number[]) => void, setGameClosed: () => void) => {
  BasicFetch({
    url: `boards/${gameId}/get_boards`,
    method: 'GET',
    onSuccess: ({ boards, calledNumbers, gameClosed }) => {
      console.log(gameClosed);
      setBoards(boards);
      setCalledNumbers(calledNumbers);
      if (gameClosed) {
        setGameClosed();
      }
    },
    onFail: () => (window as any).showAlertMessage("There was a problem fetching boards. Please try again later.")
  })
}


const GameBoards = ({ game }: { game: Game }) => {

  const [boards, setBoards] = useState<Board[]>([]);
  const [calledNumbers, setCalledNumbers] = useState<number[]>([0]);
  const [bingoAcknowledged, setBingoAcknowledged] = useState(false);
  const [gameClosed, setGameClosed] = useState(false);

  const { closestBoards, distance } = useMemo(() => {
    if (!game.gameMode) return { closestBoards: [], distance: 25 };
    const winningPatterns = game.gameMode.patterns;
    let closestBoards: Board[] = [];
    let closestCount = 0;
    let distance = 25;

    boards.forEach(board => {
      let count = 0;
      const calledSpaces = board.numbers.flat().filter(number => number.called);
      winningPatterns.forEach(pattern => {
        count = 0;
        const requiredSpaces = pattern.spaces.filter(space => space.required);
        // count the number of required spaces that are called
        calledSpaces.forEach(calledSpace => {
          if (requiredSpaces.find(requiredSpace => requiredSpace.column === calledSpace.column && requiredSpace.row === calledSpace.row)) {
            count++;
          }
        });
        // if the count is greater than the current closest count, update the closest count and closest boards
        if (count > closestCount) {
          closestCount = count;
          closestBoards = [board];
          distance = requiredSpaces.length - closestCount;
        } else if (count === closestCount) {
          closestBoards.push(board);
        }
      });

    })
    return { closestBoards, distance };
  }, [boards, game.gameMode]);

  // set up interval to get boards every 2 seconds and refreshes the boards if the gameId changes 
  // clear the interval when the component unmounts or if distance is 0
  useInterval(() => getBoards(game.id, setBoards, setCalledNumbers, () => setGameClosed(true)), gameClosed ? null : 2000);

  return (
    <div>
      <WinnerModal boards={closestBoards} closeModal={() => setBingoAcknowledged(true)} show={distance === 0 && !bingoAcknowledged} />
      <h4>Called Numbers</h4>
      <div className="mt-2 d-flex flex-column mb-5">
        <CalledNumberBoard calledNumbers={calledNumbers} />
        <hr />
        <Tabs defaultActiveKey="closest" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="closest" title="Closest to Winning">
            {distance < 25 && <div className="mt-3">
              <Alert variant='info'>
                <h6>
                  {distance} spaces away from winning
                </h6>
              </Alert>
            </div>}
            <div className="mt-3 mx-auto d-flex flex-row flex-wrap gap-3">
              {closestBoards.map((board, i) => {
                return (
                  <BoardDisplay
                    key={i}
                    board={board}
                  />
                )
              })}
            </div>
          </Tab>
          <Tab eventKey="boards" title="Boards">
            <div className="mt-3 mx-auto d-flex flex-row flex-wrap gap-3">
              {boards.map((board, i) => {
                return (
                  <BoardDisplay
                    key={i}
                    board={board}
                  />
                )
              })}
            </div>
          </Tab>
          {game.gameMode && <Tab eventKey={'winningPatterns'} title="Winning Patterns">
            <h6>
              {game.gameMode.name}
            </h6>
            <div className='mt-3 d-flex flex-row flex-wrap gap-2'>

              {game.gameMode.patterns.map((pattern: Pattern) => <div>
                <PatternDisplay pattern={pattern} size={100} />
              </div>)}
            </div>
          </Tab>}
        </Tabs>
      </div>
    </div>
  );
}


export const PlayGame = ({
  backToMenu,
  eventId,
}: {
  backToMenu: () => void;
  eventId: number;
}) => {
  const url = new URL(window.location.href);
  const selectedGameId = parseInt(url.searchParams.get('playingGame') || '');
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Button size='sm' onClick={backToMenu}>Back to Menu</Button>
      </div>
      <div className="my-3">
        <GamePicker eventId={eventId} gameId={selectedGameId} onSelect={
          (game: Game | null) => {
            if (game) {
              if (game.id !== selectedGameId) {
                const url = new URL(window.location.href);
                url.searchParams.set('playingGame', game.id.toString());
                url.searchParams.set('eventId', eventId.toString());
                window.history.pushState({}, '', url.toString());
                window.location.reload();
              } else {
                setSelectedGame(game);
              }
            }
          }
        } />
      </div>
      {selectedGame && <GameBoards game={selectedGame} />}
    </div>
  );
};