import { Venue, blankVenue } from "./Venue";
import { Game } from './Game';
import { User } from "./User";

export interface EventPlayer {
    player: User
    boardCount: number
}

export interface Event {
    id: number
    title: string
    description: string
    organization_id: number
    eventDate: {
        start: Date,
        end: Date
    }
    venue: Venue
    games: Game[]
    players: EventPlayer[]
}

export const blankEvent = {
    id: -1,
    title: '',
    description: '',
    organization_id: -1,
    eventDate: {
        start: new Date(),
        end: new Date()
    },
    venue: blankVenue,
    games: [],
    players: []
}