import React, { useEffect, useState } from 'react';
import { BasicFetch } from '../../../utils/BasicFetch';
import { Button, Table, Modal, Badge } from 'react-bootstrap';
import { Event } from '../../../types/Event';
import { BingoCaller } from '../../caller/Caller';
import { PatternDisplay } from '../../../utilityComponents/PatternDisplay';
import { GameMode } from '../../../types/GameMode';
import { Pattern } from '../../../types/Pattern';
import { Game } from '../../../types/Game';


export const AssignGameModeModal = ({ closeModal, show, gameId, onAssign, gameMode }: { closeModal: () => void, show: boolean, gameId: number | null, onAssign: () => void, gameMode: GameMode | null }) => {
  const [gameModes, setGameModes] = useState([]);
  const [selectedGameMode, setSelectedGameMode] = useState<GameMode | null>(gameMode);
  const getGameModes = () => {
    BasicFetch({
      url: '/game_modes/get',
      method: 'GET',
      onSuccess: ({ gameModes }) => {
        console.log(gameModes);
        setGameModes(gameModes);
      },
      onFail: () => (window as any).showAlertMessage("There was a problem fetching game modes. Please try again later.")
    })
  }

  const assignGameMode = () => {
    if (!selectedGameMode) {
      (window as any).showAlertMessage("Please select a game mode");
      return;
    }
    BasicFetch({
      url: `game/${gameId}/assign_game_mode`,
      method: 'POST',
      data: { gameModeId: selectedGameMode?.id },
      onSuccess: () => {
        onAssign();
      },
      onFail: () => (window as any).showAlertMessage("There was a problem assigning game mode. Please try again later.")
    })

  }

  useEffect(() => {
    getGameModes();
  }, [])
  return <div>
    <Modal show={show} onHide={closeModal} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Assign Game Mode</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-between'>
          <select className='form-control' value={selectedGameMode?.id} onChange={(e) => {
            setSelectedGameMode(gameModes.find((gameMode: any) => gameMode.id === parseInt(e.target.value)) || null)
          }}>
            <option value={undefined}>Select Game Mode</option>
            {gameModes.map((gameMode: GameMode) => <option value={gameMode.id}>{gameMode.name}</option>)}
          </select>
        </div>
        <div className='mt-3 d-flex flex-row flex-wrap gap-2'>
          {selectedGameMode && selectedGameMode.patterns.map((pattern: Pattern) => <div>
            <PatternDisplay pattern={pattern} size={100} />
          </div>)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={assignGameMode}>Assign</Button>
      </Modal.Footer>
    </Modal>
  </div>
}


export const EditEventGames = ({ eventId, goBack }: { eventId: number | null, goBack: () => void }) => {
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [fetching, setFetching] = useState<boolean>(true);
  const [selectedGameId, setSelectedGameId] = useState<number | null>(null);
  const [editingGameId, setEditingGameId] = useState<number | null>(null);

  const getEvent = () => {
    BasicFetch({
      url: `events/${eventId}/get_event`,
      method: 'GET',
      onSuccess: (response) => {
        setCurrentEvent(response.event);
      },
      onFail: () => (window as any).showAlertMessage("There was a problem fetching event. Please try again later."),
      onFinish: () => setFetching(false)
    })
  }

  useEffect(() => {
    if (fetching) {
      getEvent();
    }
  }, [fetching])

  return <>
    {!selectedGameId ? <>
      <AssignGameModeModal closeModal={() => {
        setEditingGameId(null);
      }}
        show={!!editingGameId}
        gameId={editingGameId}
        gameMode={currentEvent?.games.find(game => game.id === editingGameId)?.gameMode || null}
        onAssign={() => {
          setEditingGameId(null);
          setFetching(true);
        }} />
      {!eventId && <div>No event selected</div>}
      {eventId && <>
        <Button onClick={() => goBack()} variant='outline-primary'>Back</Button>
        <div className='col-10 col-lg-6 col-md-8 mx-auto mt-3'>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th>#</th>
                <th>Game Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEvent?.games.map((game, index) => (
                <tr key={game.id}>
                  <td>
                    <div>Game #{index + 1}</div>
                    {game.closedTime && <Badge className='mt-2 bg-secondary' pill>
                      Closed
                    </Badge>}
                  </td>
                  <td>
                    {
                      game.gameMode ? <div onClick={() => {
                        setEditingGameId(game.id);
                      }}>
                        {game.gameMode.name}
                      </div> : <i style={{ cursor: 'pointer' }} onClick={() => {
                        setEditingGameId(game.id);
                      }}>Assign Game Mode</i>
                    }
                  </td>
                  <td>
                    <Button onClick={() => setSelectedGameId(game.id)}>Call</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>}
    </> : <BingoCaller gameId={selectedGameId} backToGames={() => setSelectedGameId(null)} />}
  </>
}