import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { BasicFetch } from '../utils/BasicFetch';
import { User } from '../types/User';
import { Event } from '../types/Event';
import { CreateEventModal } from './admin/events/EventList';
import ManageEventModal from '../utilityComponents/ManageEventModal';

export const Menu = ({ setScreen, currentUser }: {
  setScreen: (screen: string, gameId?: number | null) => void;
  currentUser: User | null;
}) => {
  const [upcomingRsvps, setUpcomingRsvp] = useState<Event[]>([]);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showManageEvent, setShowManageEvent] = useState(false);
  const getUpcomingRsvp = () => {
    BasicFetch({
      url: '/events/upcoming_rsvp',
      method: 'GET',
      onSuccess: ({ events }) => {
        const filteredEvents = events.filter((event: Event) => {
          return new Date(event.eventDate.start).getTime() > Date.now();
        });
        setUpcomingRsvp(filteredEvents);
      },
      onFail: () => {
        console.log('failed')
      }
    })
  }

  useEffect(() => {
    getUpcomingRsvp();
  }, [])
  return (
    <div>
      <CreateEventModal
        closeModal={() => setShowCreateEvent(false)}
        show={showCreateEvent}
        editingEvent={null}
        onCreate={() => {
          setShowCreateEvent(false);
          getUpcomingRsvp();
        }}
      />
      <ManageEventModal
        show={showManageEvent}
        manageGames={() => setScreen('viewGames', upcomingRsvps[0].id)}
        managePlayers={() => setScreen('viewPlayers', upcomingRsvps[0].id)}
        handleClose={() => setShowManageEvent(false)}
      />

      <h1 className='mt-5'>
        Pocono Family Bingo
      </h1>
      <div className='mt-5 d-flex flex-column'>
        {currentUser?.organization && <Button
          variant='primary'
          className='mb-3'
          onClick={() => setScreen('eventList')}
        >
          My Events
        </Button>}
        <Button
          variant='outline-primary'
          className='mb-3'
          onClick={() => setScreen('eventCalendar')}
        >
          Find Event
        </Button>
        {false && <Button
          variant='outline-primary'
          className='mb-3'
          onClick={() => setScreen('joinEvent')}
        >
          Join Event
        </Button>}

        <hr />
        <h3>Upcoming Events</h3>

        {
          upcomingRsvps.length > 0 &&
          <Card className='col-lg-6 col-md-8 col-10 mx-auto mt-3 mb-4'>
            <Card.Header>
              <h4>{
                upcomingRsvps[0].title
              }</h4>
            </Card.Header>
            <Card.Body>
              <div>
                <h5>
                  {new Date(upcomingRsvps[0].eventDate.start).toLocaleDateString("en-US")}
                </h5>
                <div className='my-3'>
                  {upcomingRsvps[0].description}
                </div>
                <div className='d-flex justify-content-center'>
                  <div className='d-flex flex-column me-3'>
                    <strong>Start:</strong>
                    {new Date(upcomingRsvps[0].eventDate.start).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' })}
                  </div>
                  <div className='d-flex flex-column'>
                    <strong>End:</strong>
                    {new Date(upcomingRsvps[0].eventDate.end).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' })}
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column'>
                {
                  upcomingRsvps[0].organization_id === currentUser?.organization.organizationInfo.id &&
                  <Button
                    className='mt-3'
                    onClick={() => setShowManageEvent(true)}
                  >
                    Manage Event
                  </Button>
                }

                <Button className='mt-3' onClick={() => setScreen('playGame', upcomingRsvps[0].id)}>
                  Play Game
                </Button>
              </div>
            </Card.Body>
          </Card>}
        {upcomingRsvps.length === 0 && !currentUser?.organization.permissions.createEvents && <div>
          <p>No upcoming RSVPs</p>
          <p>Check out the event calendar to find an event to join.</p>
        </div>}

        {upcomingRsvps.length === 0 && currentUser?.organization.permissions.createEvents && <div>
          <p>No upcoming RSVPs</p>
          <p>Click the button below to create an event!</p>
          <Button
            variant='secondary'
            className='mb-3 text-light'
            onClick={() => setShowCreateEvent(true)}
          >
            Create Event
          </Button>
        </div>}

      </div>
    </div >
  );
};

