import React, { useState, useEffect } from 'react';
import { Form, FormSelect, Button } from 'react-bootstrap';
import { BasicFetch } from '../utils/BasicFetch';
import { Game } from '../types/Game';

export const GamePicker = ({
  eventId,
  gameId,
  onPick,
  onSelect
}: {
  eventId: number,
  gameId?: number,
  onPick?: (gameId: number) => void,
  onSelect?: (game: Game | null) => void
}) => {
  const [selectableGames, setSelectableGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const getGames = () => {
    BasicFetch({
      url: `events/${eventId}/games/get`,
      method: 'GET',
      onSuccess: (data) => {
        setSelectableGames(data.games);
      },
      onFail: () => {
        //(window as any).showAlertMessage("Failed to get games. Please try again later")
      }
    })
  }

  useEffect(() => {
    getGames();
  }, [eventId]);

  useEffect(() => {
    if (gameId) {
      const selectedGame = selectableGames.find(game => game.id === gameId);
      setSelectedGame(selectedGame || null)
      onSelect?.(selectedGame || null)
    } else if (selectableGames.length) {
      onSelect?.(selectableGames[0])
    }
  }, [selectableGames])


  return <div>
    <Form>
      <FormSelect value={selectedGame?.id} onChange={(e) => {
        const selectedGame = selectableGames.find(game => game.id === parseInt(e.target.value));
        onSelect?.(selectedGame || null)
        setSelectedGame(selectedGame || null)
      }}>
        {
          selectableGames.map((game, index) => (
            <option value={game.id}>{game.name || `Game #${index + 1}`}</option>
          ))
        }
      </FormSelect>
      {onPick && <Button className='mt-3' onClick={() => selectedGame ? onPick(selectedGame?.id) : null}>
        Select
      </Button>}
    </Form>
  </div>
}