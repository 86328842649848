import React from 'react';
import { Venue, blankVenue } from '../../types/Venue';
import { BasicFetch } from '../../utils/BasicFetch';
import { Form, FormControl, Button } from 'react-bootstrap';


export const CreateVenue = ({ backToMenu }: { backToMenu: () => void }) => {
    const [currentVenue, setCurrentVenue] = React.useState<Venue>(blankVenue);

    const createVenue = () => {
        const data = new FormData();
        data.append('name', currentVenue.name);
        data.append('address1', currentVenue.address1);
        data.append('address2', currentVenue.address2);
        data.append('city', currentVenue.city);
        data.append('state', currentVenue.state);
        data.append('postalCode', currentVenue.postalCode);

        BasicFetch({
            url: 'venues/create',
            method: 'POST',
            data,
            contentType: 'multipart/form-data',
            onSuccess: () => {
                (window as any).showAlertMessage("Venue Successfully created.", "success");
                backToMenu();
            },
            onFail: () => {
                (window as any).showAlertMessage("Failed to create Venue. Please try again later.");
            }
        })
    }

    return <Form>
        <h4>Create Venue</h4>
        <div className='mb-3'>
            <label>Venue Name</label>
            <FormControl value={currentVenue.name} onChange={(e) => setCurrentVenue({ ...currentVenue, name: e.target.value })} />
        </div>
        <div className='mb-3'>
            <label>Venue Address</label>
            <FormControl value={currentVenue.address1} onChange={(e) => setCurrentVenue({ ...currentVenue, address1: e.target.value })} />
        </div>

        <div className='mb-3'>
            <label>Venue Address 2</label>
            <FormControl value={currentVenue.address2} onChange={(e) => setCurrentVenue({ ...currentVenue, address2: e.target.value })} />
        </div>

        <div className='mb-3'>
            <label>Venue City</label>
            <FormControl value={currentVenue.city} onChange={(e) => setCurrentVenue({ ...currentVenue, city: e.target.value })} />
        </div>
        <div className='mb-3'>
            <label>Venue State</label>
            <FormControl value={currentVenue.state} onChange={(e) => setCurrentVenue({ ...currentVenue, state: e.target.value })} />
        </div>
        <div className='mb-3'>
            <label>Venue Zipcode</label>
            <FormControl value={currentVenue.postalCode} onChange={(e) => setCurrentVenue({ ...currentVenue, postalCode: e.target.value })} />
        </div>

        <Button onClick={() => backToMenu()} variant='outline-secondary' className='me-2'>Back to Menu</Button>
        <Button onClick={() => createVenue()} >Create Venue</Button>
    </Form>
}
