import React, { useState, useEffect } from "react";
import { BasicFetch } from "../../../utils/BasicFetch";
import { Button, Dropdown, FormControl, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { EventPlayer, Event } from "../../../types/Event";

export const CreditModal = ({
  closeModal,
  show,
  onCredit,
  eventId,
  userId,
  userName
}: {
  closeModal: () => void,
  show: boolean,
  onCredit: () => void,
  eventId: number,
  userId: number,
  userName: string
}) => {
  const [boardsPerGame, setBoardsPerGame] = useState<number>(9);

  const handleCredit = () => {
    BasicFetch({
      url: `events/${eventId}/user/${userId}/boards`,
      method: 'POST',
      data: {
        boardsPerGame
      },
      onSuccess: () => {
        onCredit();
      },
      onFail: () => (window as any).showAlertMessage("There was a problem giving boardss. Please try again later.")
    })
  }



  return <Modal show={show} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>{userName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>How many boards per game will you give this player?</p>
      <FormControl
        type="number"
        value={boardsPerGame}
        onChange={(e: any) => setBoardsPerGame(e.target.value)}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={closeModal}>
        Close
      </Button>
      <Button variant="primary" onClick={handleCredit}>
        Give Boards
      </Button>
    </Modal.Footer>
  </Modal>
}

export const EventPlayersList = ({ backToEvents, backToMenu, eventId }: { backToEvents?: () => void, backToMenu?: () => void, eventId: number | null }) => {
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const [showCreditModal, setShowCreditModal] = useState<boolean>(false);
  const [editingPlayerId, setEditingPlayerId] = useState<number | null>(null);

  const getPlayers = () => {
    BasicFetch({
      url: `events/${eventId}/get_event`,
      method: 'GET',
      onSuccess: ({ event }: { event: Event }) => {
        setCurrentEvent(event);
      },
      onFail: () => (window as any).showAlertMessage("There was a problem fetching players. Please try again later.")
    })
  }
  const removePlayer = (userId: number) => {
    BasicFetch({
      url: `events/${eventId}/user/${userId}/remove`,
      method: 'DELETE',
      onSuccess: () => {
        getPlayers();
      },
      onFail: () => (window as any).showAlertMessage("There was a problem removing player. Please try again later.")
    })
  }

  useEffect(() => {
    getPlayers();
  }, [eventId])

  const editingPlayer = currentEvent?.players.find(player => player.player.id === editingPlayerId);
  const editingPlayerName = editingPlayer ? `${editingPlayer.player.first_name} ${editingPlayer.player.last_name}` : '';
  return <>
    <div className='d-flex flex-row mb-3'>
      {backToEvents && <Button onClick={backToEvents} variant="outline-primary">
        <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
        Back to Events
      </Button>}
      {backToMenu && <Button onClick={backToMenu} variant="outline-primary">
        <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
        Back to Menu
      </Button>}
    </div>
    {!eventId && <div>No event selected</div>}

    {eventId && <>
      {editingPlayerId && <CreditModal
        closeModal={() => setShowCreditModal(false)}
        show={showCreditModal}
        userName={editingPlayerName}
        onCredit={() => {
          setShowCreditModal(false);
          getPlayers();
        }}
        eventId={eventId}
        userId={editingPlayerId}
      />}
      <h4>Players ({currentEvent?.players.length || 0})</h4>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <th>
              Name
              <br />
              <small className="text-truncate">Email</small>
            </th>
            <th>Boards</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentEvent?.players.map(player => {
            return <tr key={player.player.id}>
              <td>
                {player.player.first_name} {player.player.last_name}
                <br />
                <div className="text-truncate" style={{ maxWidth: '10rem' }}>
                  <small>{player.player.email}</small>
                </div>
              </td>
              <td>{player.boardCount / currentEvent.games.length}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <FontAwesomeIcon icon={faPen} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                      setEditingPlayerId(player.player.id);
                      setShowCreditModal(true);
                    }}>Give Boards</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => removePlayer(player.player.id)}>Remove</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    </>}
  </>
}