import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';



export const HelpModal = ({ show, handleClose }: { show: boolean, handleClose: () => void }) => {
  const [step, setStep] = useState(1);

  const handleNextStep = () => setStep(step + 1);
  const handlePreviousStep = () => setStep(step - 1);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <p>Step 1: Feature 1 description</p>;
      case 2:
        return <p>Step 2: Feature 2 description</p>;
      case 3:
        return <p>Step 3: Feature 3 description</p>;
      // Add more cases for additional steps
      default:
        return null;
    }
  };

  return (
    <Modal show={show} size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>App Features Guide</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderStepContent()}</Modal.Body>
      <Modal.Footer>
        {step > 1 && (
          <Button variant="secondary" onClick={handlePreviousStep}>
            Previous
          </Button>
        )}
        {step < 3 && (
          <Button variant="primary" onClick={handleNextStep}>
            Next
          </Button>
        )}
        {step === 3 && (
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default HelpModal;