import { Pattern } from './Pattern';
export interface GameMode {
    id: number
    name: string
    organizationId: number
    patterns: Pattern[]
}

export const blankGameMode = {
    id: -1,
    name: "",
    organizationId: -1,
    patterns: []
}