export interface Venue {
    id: number
    name: string
    address1: string
    address2: string
    city: string
    postalCode: string
    state: string
    country: string
}

export const blankVenue = {
    id: -1,
    name: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    state: '',
    country: ''
}