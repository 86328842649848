import React from "react";

const LETTERS = ['B', 'I', 'N', 'G', 'O'];


export const CalledNumberBoard = ({ calledNumbers }: { calledNumbers: number[] }) => {

  return (
    <>
      {Array.from({ length: 5 }, (_, letter) => letter + 1).map((letter) => {
        return (
          <div className='d-flex flex-row flex-fill mx-auto mb-1 called-number-board'>
            <div className='mx-1'>
              <div className={'d-flex justify-content-center align-items-center board-number h-100 bg-dark text-light'} style={{ border: '1px solid black' }}>
                {LETTERS[letter - 1]}
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap gap-1">
              {Array.from({ length: 15 }, (_, i) => i + 1).map((number) => {
                const displayNumber = number + ((letter - 1) * 15);
                return (
                  <div key={number}>
                    <div className='d-flex flex-column justify-content-center align-items-center board-number' style={{ border: '1px solid black' }}>
                      <div className={calledNumbers.includes(displayNumber) ? 'bg-primary round text-light text-strong d-flex' : 'd-flex'}
                        style={{ width: '35px', height: '35px' }}
                      >
                        <div className="m-auto">
                          {displayNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      )}
    </>
  )
}