import React from 'react';
import { Pattern } from '../types/Pattern';

export const PatternDisplay = ({
  pattern,
  setPattern,
  size
}: {
  pattern: Pattern,
  setPattern?: (pattern: Pattern) => void,
  size: number
}) => {
  return <div className='pattern-display d-flex flex-row flex-wrap' style={{ height: size, width: size }}>
    {
      pattern.spaces.map((position, i) => {
        return <div
          className={`pattern-position${!!position.required ? ' active' : ''}`}
          onClick={() => {
            if (setPattern) {
              const newPattern = {
                ...pattern, spaces: pattern.spaces.map((p, j) => {
                  if (i === j) {
                    return { ...p, required: !p.required }
                  }
                  return p;
                })
              }
              setPattern(newPattern);
            }
          }}
        />
      })
    }
  </div>
}