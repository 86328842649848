import React, { useEffect, useState } from 'react';
import { BasicFetch } from '../utils/BasicFetch';
import type { Screens } from '../App';
import { Button } from 'react-bootstrap';

const RegisterForm = ({ showLogin }: { showLogin: (email: string) => void }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);

  const register = () => {
    if (password !== password2 || password.length < 8) {
      setPasswordInvalid(true);
      return;
    }
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName)

    BasicFetch(
      {
        url: `user/create_account`,
        data: formData,
        method: 'POST',
        contentType: 'multipart/form-data',
        onSuccess: (data) => {
          console.log(data);
          if (data.success) {
            showLogin(email);
            (window as any).showAlertMessage("Successfully created account, you may now login.", "success")
          } else {
            (window as any).showAlertMessage("There was an issue creating account. Please check your inputs and try again.")
          }
        },
        onFail: () => {
          (window as any).showAlertMessage("There was an issue creating account. Please try again later.")
        }
      })
  }

  return (
    <form id="register-form">
      <div className="register-field mt-3">
        <h6>First Name:</h6>
        <input id="first-name-data" type="text" value={firstName} onChange={(event) => setFirstName(event.target.value)} className="form-control form-control-dark glow home-input" required autoComplete='given-name' />
      </div>
      <div className="register-field mt-3">
        <h6>Last Name:</h6>
        <input id="last-name-data" type="text" value={lastName} onChange={(event) => setLastName(event.target.value)} className="form-control form-control-dark glow home-input" required autoComplete='family-name' />
      </div>
      <div className="">
        <h6>Email:</h6>
        <input id="email-data" value={email} onChange={(event) => setEmail(event.target.value)} type="email" className="form-control form-control-dark glow home-input" required autoComplete='email' />
      </div>
      <div className="text-center mt-3">
        <h6>Password:</h6>
        <input id="password" type="password" value={password} onChange={(event) => setPassword(event.target.value)} className="form-control form-control-dark glow home-input" required autoComplete='new-password' />
      </div>
      <div id="password2-container" className="text-center register-field has-validation mt-3">
        <h6>Confirm Password:</h6>
        <input id="password2" type="password" value={password2} onChange={(event) => setPassword2(event.target.value)} className={`form-control form-control-dark glow home-input ${passwordInvalid ? 'is-invalid' : ''}`} required autoComplete='new-password' />
        <div className='invalid-feedback'>Passwords must match and be atleast 8 characters long</div>
      </div>

      <div className="home-button-container mt-4 d-flex flex-column">
        <Button id="register-btn" variant='primary' onClick={() => register()} >Register</Button>
        <Button onClick={() => showLogin(email)} variant='outline-secondary' className='mt-3' >Cancel</Button>
      </div>
    </form>
  )
}

export const ResetPasswordForm = ({
  email = '',
  showLogin,
}: {
  email: string,
  showLogin: () => void,
}) => {
  const [resetEmail, setResetEmail] = useState<string>(email);
  const [resetting, setResetting] = useState<boolean>(false);

  const sendReset = () => {
    let formData = new FormData();
    formData.append("email", resetEmail);

    BasicFetch(
      {
        url: `user/reset_password`,
        data: formData,
        method: 'POST',
        contentType: 'multipart/form-data',
        onSuccess: (data) => {
          (window as any).showAlertMessage("Successfully requested password reset. Please check your email.", "success")
          showLogin();
        },
        onFail: () => {
          (window as any).showAlertMessage("Failed to request password reset. Please try again later.")
        },
        onFinish: () => {
          setResetting(false);
        }
      })
  }
  useEffect(() => {
    if (resetting) {
      sendReset();
    }
  }, [resetting])

  return (
    <form id="reset-password-form">
      <div className="">
        <h6>Email:</h6>
        <input id="email-data" type="email" value={resetEmail} onChange={(event) => setResetEmail(event.target.value)} className="form-control form-control-dark glow home-input" required autoComplete='email' />
      </div>

      <div className="home-button-container mt-4 d-flex flex-column">
        {!resetting ? <div
          onClick={() => setResetting(true)}
          id="reset-password-btn"
          className="login-button glow button"
        >
          Request Reset Password Link
        </div> : <i className='fa fa-spinner fa-spin' />}
        <div onClick={showLogin} className="cancel-btn login-button me-2 glow button">Cancel</div>
      </div>
    </form>
  )
}

export const LoginForm = ({
  showMenu,
  showRegister,
  showReset
}: {
  showMenu: () => void,
  showRegister: () => void,
  showReset: (email: string) => void,
}) => {
  const [email, setEmail] = useState<string>(localStorage.getItem("loginEmail") || '');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(!!localStorage.getItem("loginEmail"))
  const login = () => {
    if (!email || !password) {
      (window as any).showAlertMessage("Please enter a valid email and password")
      return;
    }
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    BasicFetch(
      {
        url: `login`,
        data: formData,
        method: 'POST',
        contentType: 'multipart/form-data',
        onSuccess: (data) => {
          if (data.message === "Email not found or password is incorrect") {
            (window as any).showAlertMessage(data.message)
            throw Error();
          }
          showMenu();
          if (remember) {
            localStorage.setItem("loginEmail", email);
          } else {
            localStorage.setItem("loginEmail", '');
          }
        },
        onFail: () => {
          (window as any).showAlertMessage("There was an error logging in")

          console.log("Fail")
        },
      })
  }
  return (
    <div>
      <div className="">
        <h6>Email:</h6>
        <input id="email-data" type="email" value={email} onChange={(event) => setEmail(event.target.value)} className="form-control form-control-dark glow home-input" required autoComplete='email' />
      </div>
      <div className="text-center mt-3" >
        <h6>Password:</h6>
        <input id="password" type="password" value={password} onChange={(event) => setPassword(event.target.value)} className="form-control form-control-dark glow home-input" required autoComplete='current-password' />
      </div>
      <div className="text-center mt-3 d-flex flex-row justify-content-center">
        <input id="remember" type="checkbox" checked={remember} onClick={() => setRemember(!remember)} className="me-2" />
        <div className="">Remember Me Next Time</div>
      </div>
      <div className="home-button-container mt-4 d-flex flex-column">
        <Button type="submit" id="login-btn" variant='primary' onClick={() => login()}>Login</Button>
        <Button id="create-acct-btn" variant='outline-secondary' onClick={showRegister} className="mt-3">Create Account</Button>
        <div id="forgot-password" onClick={() => showReset(password)} className=" mt-2 mt-3">Forgot Your Password?</div>
      </div>
    </div>
  )
}
export const LoginContainer = ({
  setScreen
}: {
  setScreen: (screenName: keyof Screens) => void
}) => {
  const [showLogin, setShowLogin] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<string>(localStorage.getItem("loginEmail") || '');

  return (
    <div className="p-4 mb-5 fadeIn animated">
      <div className="container login-container">
        <div id="logo" className="text-center mb-5">
          <h1>Pocono Family Bingo</h1>
        </div>
        <div className="text-center col col-md-6 mx-auto">
          {showLogin && <LoginForm
            showMenu={() => setScreen("menu")}
            showRegister={() => { setShowLogin(false); setShowRegister(true) }}
            showReset={() => { setShowLogin(false); setShowReset(true) }}
          />}
          {showRegister && <RegisterForm showLogin={(email) => { setCurrentEmail(email); setShowLogin(true); setShowRegister(false) }} />}
          {showReset && <ResetPasswordForm email={currentEmail} showLogin={() => { setShowLogin(true); setShowReset(false) }} />}
        </div>
      </div>
    </div>
  )
}
