import React, { useState, useEffect } from 'react';
import { Form, FormSelect, Button } from 'react-bootstrap';
import { BasicFetch } from '../utils/BasicFetch';
import { Organization } from '../types/Organization';

export const OrganizationPicker = ({
  organizationId,
  onPick,
  onSelect
}: {
  organizationId?: number | null,
  onPick?: (organizationId: number) => void,
  onSelect?: (organization: Organization | null) => void
}) => {
  const [selectableOrganizations, setSelectableOrganizations] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const getOrganizations = () => {
    BasicFetch({
      url: `organization/get_all`,
      method: 'GET',
      onSuccess: (organizations) => {
        setSelectableOrganizations(organizations);
      },
      onFail: () => {
        //(window as any).showAlertMessage("Failed to get organizations. Please try again later")
      }
    })
  }

  useEffect(() => {
    getOrganizations();
  }, [])


  useEffect(() => {
    if (organizationId) {
      const selectedOrganization = selectableOrganizations.find(organization => organization.id === organizationId);
      setSelectedOrganization(selectedOrganization || null)
      onSelect?.(selectedOrganization || null)
    } else if (selectableOrganizations.length) {
      onSelect?.(selectableOrganizations[0])
    }
  }, [selectableOrganizations, organizationId])


  return <div>
    <Form>
      <FormSelect value={selectedOrganization?.id} onChange={(e) => {
        const selectedOrganization = selectableOrganizations.find(organization => organization.id === parseInt(e.target.value));
        onSelect?.(selectedOrganization || null)
        setSelectedOrganization(selectedOrganization || null)
      }}>
        <option value={'-1'}>Select Organization</option>
        {
          selectableOrganizations.map((organization, index) => (
            <option value={organization.id}>{organization.name || `Organization #${index + 1}`}</option>
          ))
        }
      </FormSelect>
      {onPick && <Button className='mt-3' onClick={() => selectedOrganization ? onPick(selectedOrganization?.id) : null}>
        Select
      </Button>}
    </Form>
  </div>
}