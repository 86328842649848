import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ManageEventModal = ({
  show,
  handleClose,
  managePlayers,
  manageGames
}: {
  show: boolean,
  handleClose: () => void,
  managePlayers: () => void,
  manageGames: () => void
}) => {


  const handleManagePlayers = () => {
    managePlayers();
    handleClose();
  };

  const handleManageGames = () => {
    manageGames();
    handleClose();
  };

  return (

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column'>
          <Button variant="primary" className='mb-3' onClick={handleManagePlayers}>
            Manage Players
          </Button>
          <Button variant="primary" onClick={handleManageGames}>
            Manage Games
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageEventModal;