interface BasicFetchProps {
    url: string,
    method: string,
    data?: any,
    extra?: any,
    contentType?: string, // 'multipart/form-data' | 'application/json'
    onSuccess: (data: any) => void,
    onFail: () => void,
    onFinish?: () => void,
}


export const BasicFetch = ({
    url,
    method,
    data,
    extra,
    contentType = 'application/json',
    onSuccess,
    onFail = () => { },
    onFinish = () => { }
}:
    BasicFetchProps
) => {
    //const serverLocation = 'http://127.0.0.1:5000';
    const serverLocation = 'https://poconofamilybingo.com';
    let body = JSON.stringify(data);
    let searchParams = new URLSearchParams({ ...extra, api_key: localStorage.getItem("cookie") });
    url = `${serverLocation}/${url}?${searchParams}`;
    if (contentType !== 'application/json') {
        body = data;
        fetch(url, {
            method,
            mode: 'cors',
            credentials: 'same-origin', // include, *same-origin, omit
            // headers: {
            //     'Content-Type': contentType
            // },
            body  // body data type must match "Content-Type" header
        })
            .then(response => response.json())
            .then(data => {
                if (data.message && data.message === 'Bad token') {
                    (window as any).backToLogin();
                }
                if (data.token) {
                    localStorage.setItem("cookie", data.token);
                }
                console.log('Success:', data);
                onSuccess(data);
            })
            .finally(() => {
                onFinish();
            })
            .catch((error) => {
                onFail();
                console.error('Error:', error);
            });;
    } else {

        fetch(url, {
            method,
            mode: 'cors',
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': contentType
            },
            body
        })
            .then(response => response.json())
            .then(data => {
                if (data.message && data.message === 'Bad token') {
                    (window as any).backToLogin();
                }
                onSuccess(data);
                if (data.token) {
                    localStorage.setItem("cookie", data.token)
                }
            }).finally(() => {
                onFinish();
            })
            .catch((error) => {
                onFail()
                console.error('Error:', error);
            });
    }

}