import React, { useState, useMemo, useEffect } from 'react';
import { BasicFetch } from '../../utils/BasicFetch';
import { CalledNumberBoard } from '../../utilityComponents/CalledNumberBoard';
import { Game } from '../../types/Game';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { AssignGameModeModal } from '../admin/events/EditEventGames';
import { PatternDisplay } from '../../utilityComponents/PatternDisplay';
import { Board } from '../../types/Board';
import { BoardDisplay } from '../play/PlayGame';
import { on } from 'events';

const LETTERS = ["B", "I", "N", "G", "O"];
const NUMBER_OF_BALLS = 15;


const GameCloseModal = ({
  gameId,
  closeModal,
  show,
  onGameClose
}: {
  gameId: number,
  closeModal: () => void,
  show: boolean,
  onGameClose: () => void
}) => {

  const closeGame = () => {
    BasicFetch({
      url: `game/${gameId}/close`,
      method: 'POST',
      onSuccess: () => {
        closeModal();
        onGameClose();
      },
      onFail: () => {
        (window as any).showAlertMessage("Game close fail. Please try again later.")
      }
    })
  }


  return <Modal show={show} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Close Game</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you want to close this game?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={closeGame} variant='outline-danger'>Close Game</Button>
    </Modal.Footer>
  </Modal>
}

const WinnerModal = ({
  boards,
  closeModal,
  show,
  calledNumbers,
  gameId,
  onGameClose
}: {
  boards: Board[],
  closeModal: () => void,
  show: boolean,
  calledNumbers: number[],
  gameId: number,
  onGameClose: () => void
}) => {

  const closeGame = () => {
    BasicFetch({
      url: `game/${gameId}/close`,
      method: 'POST',
      onSuccess: () => {
        closeModal();
        onGameClose();
      },
      onFail: () => {
        (window as any).showAlertMessage("Game close fail. Please try again later.")
      }
    })
  }


  return <Modal show={show} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Winner</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      {boards.map(board => (
        <div className='mx-auto d-flex flex-column align-items-center'>
          <h4>{board.user.first_name} {board.user.last_name}</h4>
          <div className='mt-3'>
            <BoardDisplay board={board} />
          </div>
        </div>
      ))}

    </Modal.Body>
    <Modal.Footer>
      <Button onClick={closeGame} variant='outline-primary'>Close Game</Button>
    </Modal.Footer>
  </Modal>
}

export const BingoCaller = ({ gameId, backToGames }: { gameId: number, backToGames: () => void }) => {
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const [selectedGameId, setSelectedGameId] = useState<number>(gameId);
  const [game, setGame] = useState<Game | null>(null);
  const [calledNumbers, setCalledNumbers] = useState<number[]>([]);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [winningBoards, setWinningBoards] = useState<Board[]>([]);

  const selectableNumbers = useMemo(() => {
    if (!selectedLetter) {
      return [];
    }
    const index = LETTERS.indexOf(selectedLetter);
    // TODO: do this cleaner
    const start = (index * NUMBER_OF_BALLS) + 1;
    const end = (index + 1) * NUMBER_OF_BALLS;
    const numbers = [];
    for (let x = start; x <= end; x++) {
      numbers.push(x);
    }
    return numbers;
  }, [selectedLetter]);

  const getGame = () => {
    BasicFetch({
      url: `game/${selectedGameId}/get`,
      method: 'GET',
      onSuccess: ({ game }) => {
        setGame(game);
        setCalledNumbers([...game.calledNumbers]);
      },
      onFail: () => {
        (window as any).showAlertMessage("Game not found. Please try again later.")
      }
    })
  }


  const numberCalled = (number: number) => {
    BasicFetch({
      url: `game/${selectedGameId}/number_called`,
      method: 'POST',
      data: {
        number,
      },
      onSuccess: ({ game, winners }) => {
        setCalledNumbers([...game.calledNumbers]);
        setWinningBoards([...winners]);
        setSelectedLetter(null);
      },
      onFail: () => {
        (window as any).showAlertMessage("Number called fail. Please try again later.")
      }
    })
  }

  const undoLastCall = () => {
    BasicFetch({
      url: `game/${selectedGameId}/undo_last_call`,
      method: 'POST',
      onSuccess: ({ game }) => {
        setCalledNumbers([...game.calledNumbers]);
      },
      onFail: () => {
        (window as any).showAlertMessage("Undo last call fail. Please try again later.")
      }
    })
  }


  useEffect(() => {
    if (gameId) {
      getGame();
      setSelectedGameId(gameId);
    }
  }, [gameId]);

  useEffect(() => {
    if (selectedGameId) {
      getGame();
    }
  }, [selectedGameId]);

  return (
    <>
      <GameCloseModal gameId={selectedGameId} closeModal={() => setShowCloseModal(false)} show={showCloseModal} onGameClose={getGame} />
      <WinnerModal
        boards={winningBoards}
        calledNumbers={calledNumbers}
        closeModal={() => setWinningBoards([])}
        show={!!winningBoards.length}
        onGameClose={getGame}
        gameId={selectedGameId}
      />
      <AssignGameModeModal gameId={selectedGameId} gameMode={game?.gameMode || null} closeModal={() => setShowAssignModal(false)} onAssign={
        () => {
          getGame();
          setShowAssignModal(false);
        }
      } show={showAssignModal} />
      <div className='d-flex flex-row justify-content-between mb-3'>
        <Button onClick={backToGames} variant='outline-primary'>
          <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
          Back to games
        </Button>
      </div>
      <hr />


      {game?.gameMode ? <>
        <div className='mb-3 mx-auto d-flex flex-column'>
          <CalledNumberBoard calledNumbers={calledNumbers} />
        </div>

        {game.closedTime ? <div>
          <h4>Game Closed</h4>
          <p>Game closed at {game.closedTime}</p>
          <div className='mt-3'>
            <Button onClick={() => setSelectedGameId(game.nextGameId)} variant='primary'>
              Next Game
              <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
            </Button>
          </div>
        </div> : <>
          {!selectedLetter ? <div className='d-flex flex-row justify-content-center flex-wrap'>
            {LETTERS.map(letter => (
              <div onClick={() => setSelectedLetter(letter)} className={`${letter}-ball bingo-ball`}>
                <div className='d-flex flex-column bingo-ball-face'>
                  {letter}
                </div>
              </div>
            ))}
          </div> : <div className='d-flex flex-row flex-wrap'>
            {
              selectableNumbers?.map(number => {
                const called = calledNumbers.includes(number);
                return (
                  <div
                    className={`${selectedLetter}-ball bingo-ball`}
                    onClick={() => !called && numberCalled(number)}
                    style={{
                      opacity: called ? 0.3 : 1
                    }}
                  >
                    <div className='d-flex flex-column bingo-ball-face'>
                      <div>{number}</div>
                    </div>
                  </div>
                )
              })
            }
            <Button onClick={() => setSelectedLetter(null)} variant='outline-primary' className='mt-3'>
              <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
              Cancel
            </Button>
          </div>
          }


          <div className='d-flex flex-row justify-content-center mt-5'>
            {!!calledNumbers.length &&
              <Button onClick={undoLastCall} variant='danger' className='me-3'>
                Undo Last Call
              </Button>}

            <Button onClick={() => setShowCloseModal(true)} variant='outline-primary'>
              Close Game
            </Button>
          </div>
        </>
        }

        <hr />
        <h4>Game Mode</h4>
        <h5>{game?.gameMode.name}</h5>
        <div className='d-flex flex-row justify-content-center flex-wrap gap-2'>
          {game?.gameMode.patterns.map(pattern => (
            <div>
              <PatternDisplay pattern={pattern} size={75} />
            </div>
          ))}
        </div>


      </> : <div>
        <h4>Game Mode not assigned</h4>
        <p>Assign a game mode to get started</p>
        <Button onClick={() => setShowAssignModal(true)} variant='primary'>
          Assign Game Mode
        </Button>
      </div>
      }



    </>
  )
}