import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { BasicFetch } from '../utils/BasicFetch';
import { Event } from '../types/Event';
import moment from 'moment';
import { Button, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface CalendarEvent extends Event {
  start: Date,
  end: Date
}
const RsvpEventModal = ({
  event,
  closeModal
}: {
  event: CalendarEvent | null,
  closeModal: () => void
}) => {

  const rsvp = () => {
    if (!event) {
      return
    }
    BasicFetch({
      url: `/events/${event.id}/rsvp`,
      method: 'POST',
      onSuccess: () => {
        closeModal();
      }, onFail: () => {
        (window as any).showAlertMessage('Failed to rsvp, please try again later.')
      }
    })
  }
  const eventPassed = event && event?.end.getTime() < Date.now();
  return <Modal show={!!event} onHide={closeModal}>
    <Modal.Header closeButton>
      {event?.title}
    </Modal.Header>
    <Modal.Body>
      <p>{event?.description}</p>
      <Table>
        <thead>
          <tr>
            <th>Start</th>
            <th>End</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{event?.start.toDateString()} {event?.start.toLocaleTimeString()}</td>
            <td>{event?.end.toDateString()} {event?.end.toLocaleTimeString()}</td>
          </tr>
        </tbody>
      </Table>

    </Modal.Body>
    <Modal.Footer>
      <Button disabled={Boolean(!event || eventPassed)} onClick={rsvp} variant='primary'>
        RSVP
      </Button>
    </Modal.Footer>
  </Modal>
}


export const EventCalendar = ({
  backToMenu
}: {
  backToMenu: () => void
}) => {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null)
  useEffect(() => {
    BasicFetch({
      url: 'events/get',
      method: 'GET',
      onSuccess: ({ events }) => {
        setEvents(events.map((event: Event) => ({
          ...event,
          start: new Date(event.eventDate.start),
          end: new Date(event.eventDate.end)
        })));
      },
      onFail: () => {
        (window as any).showAlertMessage('Failed to get events');
      }
    });
  }, []);

  return (
    <div>
      <RsvpEventModal event={selectedEvent} closeModal={() => setSelectedEvent(null)} />
      <div className='mb-3 d-flex'>
        <Button variant="outline-secondary" onClick={backToMenu}>
          <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
          Back to Menu
        </Button>
      </div>


      <Calendar
        localizer={localizer}
        events={events}
        onSelectEvent={(event) => setSelectedEvent(event)}
        onSelectSlot={(test) => {
          console.log(test);
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div >
  );
}