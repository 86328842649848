import React, { useEffect } from "react";
import { BasicFetch } from "../../utils/BasicFetch";
import { Button, FormControl, Table, Modal, Dropdown, FormSelect } from "react-bootstrap";
import { User } from "../../types/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { OrganizationPicker } from "../../utilityComponents/OrganizationPicker";

const ROLES = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Caller" },
  { id: 3, name: "Member" },
];

const ChangeRoleModal = ({
  show,
  closeModal,
  userId,
  onRoleChange,
  role
}: {
  show: boolean;
  closeModal: () => void;
  userId: number;
  onRoleChange: () => void;
  role: number;
}) => {
  const [newRole, setNewRole] = React.useState(role);

  const changeRole = () => {
    BasicFetch({
      url: `organization/${userId}/change_role`,
      method: "POST",
      data: {
        role_id: newRole,
      },
      onSuccess: () => {
        onRoleChange();
        closeModal();
      },
      onFail: () => {
        (window as any).showAlertMessage("Failed to change role. Please try again later.");
      }
    });
  };

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Change Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormSelect
          value={newRole}
          onChange={(e) => setNewRole(parseInt(e.target.value))}
        >
          {ROLES.map((role) => (
            <option value={role.id}>{role.name}</option>
          ))}
        </FormSelect>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={changeRole}>Change</Button>
      </Modal.Footer>
    </Modal>
  );
}


const OrganizationMember = ({
  member,
  onRoleChange
}: {
  member: User;
  onRoleChange: () => void;
}) => {
  const [showChangeRole, setShowChangeRole] = React.useState(false);

  return (
    <tr>
      <ChangeRoleModal show={showChangeRole} closeModal={() => setShowChangeRole(false)} userId={member.id} onRoleChange={onRoleChange} role={member.organization.roleId} />
      <td>{member.first_name} {member.last_name}</td>
      <td>{member.organization.roleName}</td>
      <td>
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setShowChangeRole(true)}>Change Role</Dropdown.Item>
            <Dropdown.Item>Remove</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
}


const AddMemberModal = ({
  show,
  closeModal,
  organizationId,
  onAdd
}: {
  show: boolean;
  closeModal: () => void;
  organizationId: number;
  onAdd: () => void;
}) => {
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState(3);

  const addMember = () => {
    BasicFetch({
      url: `organization/${organizationId}/add_member`,
      method: "POST",
      data: {
        email,
        role_id: role,
      },
      onSuccess: () => {
        onAdd();
        setEmail("");
        closeModal();
      },
      onFail: () => {
        (window as any).showAlertMessage("Failed to add member. Please try again later.");
      }
    });
  };



  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Email</label>
          <FormControl
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
          />
        </div>
        <div>
          <label>Role</label>
          <FormSelect
            value={role}
            onChange={(e) => setRole(parseInt(e.target.value))}
          >
            {ROLES.map((role) => (
              <option value={role.id}>{role.name}</option>
            ))}
          </FormSelect>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={addMember}>Add</Button>
      </Modal.Footer>
    </Modal>
  );
}


const EditOrganizationMembers = ({
  organizationId,
}: {
  organizationId: number;
}) => {
  const [members, setMembers] = React.useState([]);
  const [showAddMember, setShowAddMember] = React.useState(false);
  const [showChangeRole, setShowChangeRole] = React.useState(false);

  const getMembers = () => {
    BasicFetch({
      url: `organization/${organizationId}/members`,
      method: "GET",
      onSuccess: (data) => {
        setMembers(data);
      },
      onFail: () => {
        (window as any).showAlertMessage("Failed to get members. Please try again later.");
      }
    });
  };

  useEffect(() => {
    getMembers();
  }, [organizationId]);

  return (
    <Table>
      <AddMemberModal show={showAddMember} closeModal={() => setShowAddMember(false)} organizationId={organizationId} onAdd={getMembers} />
      <ChangeRoleModal show={showChangeRole} closeModal={() => setShowChangeRole(false)} userId={0} onRoleChange={getMembers} role={0} />
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th>
            <Button onClick={() => setShowAddMember(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {members.map((member: User) => (
          <OrganizationMember member={member} onRoleChange={getMembers} />
        ))}
      </tbody>
    </Table >
  )
}


export const CreateOrganization = ({
  backToMenu,
  user
}: {
  backToMenu: () => void;
  user: User;
}) => {
  const [name, setName] = React.useState(user?.organization.organizationInfo.name || "");
  const [description, setDescription] = React.useState(user?.organization.organizationInfo.description || "");
  const [currentOrganizationId, setCurrentOrganizationId] = React.useState<number | null>(user?.organization.organizationInfo.id);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    BasicFetch({
      url: "organization/create",
      method: "POST",
      data: {
        id: user?.organization.organizationInfo.id,
        name,
        description
      },
      contentType: "application/json",
      onSuccess: () => {
        (window as any).showAlertMessage("Organization updated successfully.", "success");
      },
      onFail: () => {
        (window as any).showAlertMessage("Failed to create Organization. Please try again later.");
      }
    });

  };

  return (
    <div>
      <Button variant="outline-secondary" onClick={backToMenu}>
        Back to Menu
      </Button>
      <h1>Create Organization</h1>

      {user.admin && <div className="my-3">
        <OrganizationPicker
          organizationId={currentOrganizationId}
          onSelect={(organization) => {
            setCurrentOrganizationId(organization?.id || null);
            setName(organization?.name || "");
            setDescription(organization?.description || "");
          }}
        />
      </div>}

      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Name</label>
          <FormControl
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Description</label>
          <FormControl
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <Button type="submit">
          {currentOrganizationId ? 'Update' : 'Create'}
        </Button>
      </form>
      <hr />
      {
        user.admin && currentOrganizationId && <div className="mt-3">
          <EditOrganizationMembers organizationId={currentOrganizationId} />
        </div>
      }
    </div >
  );
};

