import React, { useEffect, useState } from 'react';
import { Venue } from '../types/Venue';
import { BasicFetch } from '../utils/BasicFetch';
import { FormSelect } from 'react-bootstrap';

export const VenuePicker = ({
  onPick,
  presetVenueId
}: {
  onPick: (venueId: number | null) => void,
  presetVenueId: number | null
}) => {
  const [fetching, setFetching] = useState<boolean>(true);
  const [selectedVenueId, setSelectedVenueId] = useState<number | null>(presetVenueId || null);
  const [selectableVenues, setSelectableVenues] = useState<Venue[]>([]);

  const getVenues = () => {
    BasicFetch({
      url: 'venues/',
      method: 'GET',
      onSuccess: ({ venues }) => {
        setSelectableVenues(venues);
        if (venues.length > 0) {
          setSelectedVenueId(venues[0].id);
        }
      },
      onFail: () => {
        (window as any).showAlertMessage("Failed to get venues. Please try again later.")
      },
      onFinish: () => setFetching(false)
    })
  }

  useEffect(() => {
    if (fetching) {
      getVenues()
    }
  }, [fetching])

  useEffect(() => {
    onPick(selectedVenueId);
  }, [selectedVenueId])

  return (
    <>
      {fetching && <span>Fetching Venues...</span>}
      {!fetching && <FormSelect value={selectedVenueId || undefined} onChange={(e) => setSelectedVenueId(parseInt(e.target.value))}>
        {
          selectableVenues.map(venue => (
            <option value={venue.id}>
              {venue.name || venue.address1}
            </option>
          ))
        }
      </FormSelect>}
    </>
  )
}