interface Position {
    required: boolean,
    row: number
    column: number
}
export interface Pattern {
    id: number
    spaces: Position[]
}

const EmptySpaceArray = [];
for (let i = 0; i < 25; i++) {
    EmptySpaceArray.push({ required: false, row: Math.floor(i / 5), column: i % 5 });
}


export const blankPattern = {
    id: -1,
    spaces: [...EmptySpaceArray]
}