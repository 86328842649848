import React, { useState, useEffect } from 'react';
import { BasicFetch } from '../../../utils/BasicFetch';
import { Event } from '../../../types/Event';
import { Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { EditEventGames } from './EditEventGames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
import { CreateEvent } from './CreateEvent';
import { EventPlayersList } from '../players/EventPlayersList';


const ConfirmDeleteModal = ({
  eventId,
  show,
  onDelete,
  closeModal
}: {
  eventId: number | null,
  show: boolean,
  onDelete: () => void,
  closeModal: () => void
}) => {

  const handleDelete = () => {
    BasicFetch({
      url: `events/${eventId}/delete`,
      method: 'DELETE',
      onSuccess: () => {
        onDelete();
      },
      onFail: () => (window as any).showAlertMessage("There was a problem deleting event. Please try again later.")
    })
  }

  return <Modal show={show} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Delete</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you want to delete this event?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleDelete}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
}



export const CreateEventModal = ({ closeModal, show, onCreate, editingEvent }: { closeModal: () => void, show: boolean, onCreate: () => void, editingEvent: Event | null }) => {
  return <Modal show={show} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>{editingEvent ? 'Edit' : 'Create'} Event</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <CreateEvent onCreate={onCreate} editingEvent={editingEvent} />
    </Modal.Body>
  </Modal>
}


export const EventList = ({ backToMenu }: { backToMenu: () => void }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [editingEventId, setEditingEventId] = useState<number | null>(null);
  const [showCreateEventModal, setShowCreateEventModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [viewPlayers, setViewPlayers] = useState<boolean>(false);
  const [viewGames, setViewGames] = useState<boolean>(false);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);

  const getEvents = () => {
    BasicFetch({
      url: 'events/get',
      method: 'GET',
      onSuccess: ({ events }) => {
        setEvents(events);
      },
      onFail: () => (window as any).showAlertMessage("There was a problem fetching events. Please try again later."),
      onFinish: () => setFetching(false)
    })
  }

  useEffect(() => {
    if (fetching) {
      getEvents();
    }
  }, [fetching])

  return <>
    <div className='d-flex flex-row mb-3'>
      <Button className='align-self-left' variant='outline-primary' onClick={backToMenu}>
        <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
        Back to Menu
      </Button>

      <h4 className='mx-auto'>Events</h4>
    </div>

    <CreateEventModal editingEvent={editingEvent} closeModal={() => setShowCreateEventModal(false)} show={showCreateEventModal} onCreate={() => {
      setShowCreateEventModal(false);
      setFetching(true);
    }} />
    <ConfirmDeleteModal eventId={editingEventId} closeModal={() => {
      setShowDeleteModal(false);
      setEditingEventId(null);
    }} show={!!(showDeleteModal && editingEventId)} onDelete={() => {
      setShowDeleteModal(false);
      setEditingEventId(null);
      setFetching(true);
    }} />
    {(!editingEventId || showDeleteModal) && !viewPlayers && <Table striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>
            <Button onClick={() => { setEditingEvent(null); setShowCreateEventModal(true) }}>Create Event</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {!fetching && events.length === 0 && <tr>
          <td colSpan={3}>No events found.</td>
        </tr>}
        {events.map((event) => (
          <tr key={event.id}>
            <td>{event.title}</td>
            <td>{event.description}</td>
            <td className='text-right'>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <FontAwesomeIcon className='me-2' icon={faPen} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { setEditingEvent(event); setShowCreateEventModal(true); }}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setViewGames(true); setEditingEventId(event.id) }}>View Games</Dropdown.Item>
                  <Dropdown.Item onClick={() => { setViewPlayers(true); setEditingEventId(event.id) }}>View Players</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => { setEditingEventId(event.id); setShowDeleteModal(true) }}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>}


    {editingEventId && viewGames && <EditEventGames eventId={editingEventId} goBack={() => {
      setEditingEventId(null);
      setViewGames(false);

    }} />}
    {viewPlayers && editingEventId && <EventPlayersList backToEvents={() => {
      setEditingEventId(null);
      setViewPlayers(false);
    }} eventId={editingEventId} />}
  </>
}





