import React, { useState } from 'react';
import { Pattern, blankPattern } from '../../types/Pattern';
import { BasicFetch } from '../../utils/BasicFetch';
import { FormControl, Modal, Button } from 'react-bootstrap';
import { PatternDisplay } from '../../utilityComponents/PatternDisplay';
import { GameMode, blankGameMode } from '../../types/GameMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';

const PatternModal = ({
  show, closeModal, addToGameMode
}: {
  show: boolean,
  closeModal: () => void,
  addToGameMode: (pattern: Pattern) => void
}) => {
  const [pattern, setPattern] = useState(blankPattern)
  return <Modal show={show} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Create Game Mode Pattern</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='d-flex justify-content-center'>
        <PatternDisplay pattern={pattern} setPattern={setPattern} size={300} />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => {
        addToGameMode(pattern);
        setPattern(blankPattern)
      }}>
        Add to Game Mode
      </Button>
    </Modal.Footer>
  </Modal>
}

const PatternPlaceholder = () => {
  return <div className='d-flex justify-content-center flex-column align-items-center bg-dark text-light' style={{ width: 200, height: 200, border: '1px solid black' }}>
    <FontAwesomeIcon icon={faPlus} size='2x' />
    <div className='mt-3'>
      Add Pattern
    </div>
  </div>
}

export const CreateGameMode = ({ backToMenu }: {
  backToMenu: () => void
}) => {
  const [creating, setCreating] = useState<boolean>(false);
  const [currentGameMode, setCurrentGameMode] = useState<GameMode>(blankGameMode);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false)

  const createGameMode = () => {
    if (!currentGameMode.name) {
      setErrorMessage('Name is required');
      return;
    }
    if (!currentGameMode.patterns) {
      setErrorMessage("At least one pattern is required");
      return;
    }
    setCreating(true);
    BasicFetch({
      url: 'game_modes/create',
      method: 'POST',
      data: currentGameMode,
      onSuccess: () => {
        (window as any).showAlertMessage("Game mode created", "success");
        setCurrentGameMode(blankGameMode)
      },
      onFail: () => {
        (window as any).showAlertMessage("There was an issue creating game mode. Please try again later");
      },
      onFinish: () => {
        setCreating(false);
      }
    })
  }

  return <>
    <div className="d-flex justify-content-between">
      <Button size='sm' variant='outline-primary' onClick={backToMenu}>
        <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
        Back to Menu
      </Button>
    </div>

    <PatternModal
      show={showModal}
      closeModal={() => setShowModal(false)}
      addToGameMode={(pattern) => {
        setCurrentGameMode({ ...currentGameMode, patterns: [...currentGameMode.patterns, pattern] })
        setShowModal(false)
      }
      }
    />
    <h4>Create a game mode</h4>
    <div className='mb-3'>
      <label>
        Game mode name
      </label>
      <FormControl value={currentGameMode.name} onChange={(e) => setCurrentGameMode({ ...currentGameMode, name: e.target.value })} />
    </div>
    <div className='mb-3'>
      Current Patterns:
      <div className='row'>
        <div className='d-flex flex-row flex-wrap'>
          {currentGameMode.patterns.map((pattern, index) => (
            <div className='mb-3'>
              <PatternDisplay pattern={pattern} size={200} />
              <Button
                size='sm'
                variant='outline-danger'
                className='mt-3'
                onClick={() => {
                  currentGameMode.patterns.splice(index, 1);
                  setCurrentGameMode({ ...currentGameMode, patterns: currentGameMode.patterns })
                }}
              >
                Remove
              </Button>
            </div>
          ))}
          <div onClick={() => setShowModal(true)}>
            <PatternPlaceholder />
          </div>
        </div>
      </div>
      <div>
        {errorMessage && <div className='text-danger mb-2'>
          {errorMessage}
        </div>}
        <Button disabled={creating} onClick={createGameMode}>
          Save Game Mode
        </Button>
      </div>
    </div>
  </>
}